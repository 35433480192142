body {
  min-width: fit-content;
}

.App {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  overflow: hidden;
}
